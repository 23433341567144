/* eslint-disable no-undef */
/* eslint-disable no-empty */
import memberstackDOM from "@memberstack/dom";
import axios from "axios";
const { tableau } = window;

(function () {
  "use strict";
  var memberstack = memberstackDOM.init({
    publicKey: "pk_sb_76160f2b34d44d09785c",
    appId: "app_cl6y01lor000g0wi1480t4npo",
  });
  var commonErrorText = "🛈 That's not a Tableau URL. Give it another try.";
  var apiBaseUrl = "https://dev-api.storyd.ai/v1/addin/powerpoint";

  var _getSettings = function (key) {
    return Office.context.document.settings.get(key);
  };
  var _setSettings = function (key, value) {
    Office.context.document.settings.set(key, value);
    Office.context.document.settings.saveAsync();
  };

  function generateUUID() {
    var ret = "",
      value;
    for (var i = 0; i < 32; i++) {
      value = (Math.random() * 16) | 0;
      // Insert the hypens
      if (i > 4 && i < 21 && !(i % 4)) {
        ret += "-";
      }
      // Add the next random character
      ret += (i === 12 ? 4 : i === 16 ? (value & 3) | 8 : value).toString(16);
    }
    return ret;
  }

  var user = null;
  var userPlan = "FREE";
  var vizEle = null;
  var viz;
  var vizUrl = null;
  var vizUrlType = "" // "TABLEAU" | "POWERBI";
  // var intialId = uuid();

  var isToolbarExpanded = true;

  // scaling variables
  let bAllowUpscaling = true;
  let maxUpscaleRatio = 10; // multplies with actual scale
  let lastScale = 1;
  let hasScaledAtLeastOnce = false;
  // these are just guesses unti they are overriden in onFirstVizSizeKnown (if its available, doesnt seem to be available on all dashboards)
  var vizNativeWidth = 720;
  var vizNativeHeight = 500;
  var viztoolbarheight = 34;

  function clearExistingViz() {
    let vizInstance = tableau.VizManager.getVizs()[0];

    if (vizInstance) {
      try {
        vizInstance.dispose();
      } catch (err) {
        //console.log("err in disposs", err);
      }
    }
  }

  async function postContentUrl(payload) {
    //console.log("user >>", user);
    const tokenString = user?.tokens?.accessToken;
    const result = await axios.post(`${apiBaseUrl}/content`, payload, {
      headers: {
        Authorization: `Bearer ${tokenString}`,
      },
    });
    return result.data;
  }

  async function getToken(currentToken) {
    const result = await axios.post(`${apiBaseUrl}/token`, undefined, {
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    });
    return result.data;
  }

  async function getContentUrl() {
    var exist_id = _getSettings("content_id");
    const tokenString = user?.tokens?.accessToken;
    const result = await axios.get(`${apiBaseUrl}/content/${exist_id}`, {
      headers: {
        Authorization: `Bearer ${tokenString}`,
      },
    });
    return result.data;
  }

  function setNewContentUuid() {
    var id = generateUUID();
    _setSettings("content_id", id);
    return id;
  }

  // The initialize function must be run each time a new page is loaded
  // eslint-disable-next-line office-addins/no-office-initialize
  Office.initialize = function (reason) {
    // //console.log("checking data ???? >>>", fromString("test"));

    function handleScaleViz() {
      hasScaledAtLeastOnce = false;
      if(isToolbarExpanded) { // updating container style with viztoolbarheight
        $("#containerVizCrop").css("height", `calc(100vh - ${viztoolbarheight}px)`);
      } else {
        $("#containerVizCrop").css("height", "100vh");
      } 
      scaleViz();
    }

    function scaleVizOnEvent(event) {
      if (vizEle) {
        handleScaleViz();
      }
    }

    $(document).ready(function () {
      const carouselElement = $("#helpCarousel");
      var helpCarousel = new bootstrap.Carousel(carouselElement, {
        interval: false,
        ride: true,
        wrap: false,
        touch: false,
        keyboard: false,
      });

      function handleLogoutActions() {
        unloadViz();
        navigateToScreen("screenAuthentication");
        logout();
      }

      async function handleGetContentUrl(savedVizUrl) {
        loadViz(savedVizUrl);
        toggleToolbar(true);
        try {
          const response = await getContentUrl();
          if (response.data.access) {
            $("#upgradeOverlay").hide();
            userPlan = "PRO";
          } else {
            if (savedVizUrl.includes("public.tableau.com")) {
              $("#upgradeOverlay").hide();
            } else {
              $("#upgradeOverlay").show();
            }
          }
          navigateToScreen("screenViz");
        } catch (err) {
          //console.log(err);
          navigateToScreen("screenLinkEntry");
          if (err?.response?.data?.message) {
            $("#txtUrlError").text(err?.response?.data?.message);
          }
          $("#txtUrlError").show();
          setTimeout(function () {
            $("#txtUrlError").hide();
            $("#txtUrlError").text(commonErrorText);
          }, 3000);
        }
      }

      const navigateToScreen = function (screenId) {
        $(".screen").fadeOut(300);

        if (screenId == "screenViz") {
          $("#" + screenId)
            .fadeIn(600)
            .css("display", "flex");
        } else {
          $("#" + screenId).fadeIn(600);
        }
      };

      setTimeout(async function () {
        user = _getSettings("user");
        if (user) {
          $("#txtLoggedInUser").text(user.member.auth.email);
        }
        const savedVizUrl = _getSettings("vizUrl");
        if (user && savedVizUrl) {
          handleGetContentUrl(savedVizUrl);
        } else {
          if (user) navigateToScreen("screenLinkEntry");
          else navigateToScreen("screenAuthentication");
        }
        if (userPlan) updateFieldsAsPerPlan(userPlan);
        $("#preloader").hide().remove();
      }, 500);

      // $("#btnToolbarExpand").hover(function(){
      //   $("btnToolbarExpand").show();
      // });

      $("#btnToolbarCollapse,#btnToolbarExpand").click(function () {
        try {
          const onEvent = true;
          toggleToolbar(null, onEvent);
        } catch (e) {
          //console.log(e);
        }
      });

      $("#btnLogin").click(function () {
        try {
          memberstack
            .openModal("LOGIN", null)
            .then(async ({ data }) => {
              await login(data);

              memberstack.hideModal();
              navigateToScreen("screenLinkEntry");
            })
            .catch((error) => {
              //console.log("ERROR", error);
              notify("Nope", "error");
            });
        } catch (error) {
          // console.log(error);
        }
      });
      $("#btnSignup").click(function () {
        try {
          memberstack
            .openModal("SIGNUP", {
              signup: {
                plans: ["pln_free-y3es0z13"],
              },
            })
            .then(({ data }) => {
              login(data);

              memberstack.hideModal();
              navigateToScreen("screenLinkEntry");
            })
            .catch((error) => {
              //console.log("ERROR", error);
            });
        } catch (error) {
          //console.log(error);
        }
      });
      $("#btnUpgrade,#btnOverlayUpgrade").click(function () {
        Office.context.ui.openBrowserWindow("https://www.storyd.ai/tableau-for-powerpoint/pricing");
      });
      $("#btnOverlayRefresh").click(function () {
        location.reload();
      });
      $("#btnSupport").click(function () {
        Office.context.ui.openBrowserWindow("https://help.storyd.ai/");
      });
      $("#btnAccount").click(function () {
        Office.context.ui.openBrowserWindow("https://app.storyd.ai/user/settings/");
      });
      $("#btnLogout,#btnHomeLogout").click(function () {
        handleLogoutActions();
      });

      $("#btnHelpCenter").click(function () {
        navigateToScreen("screenHelpCenter");
      });
      $("#btnHelpCenter1").click(function () {
        navigateToScreen("screenHelpCenter");
      });

      $("#btnHelpCarouselNext").click(function () {
        helpCarousel.next();
      });
      $(".carousel-indicator").click(function () {
        const index = $(this).index();
        helpCarousel.to(index);
      });
      carouselElement.on("slid.bs.carousel", function (e) {
        $(".carousel-indicator").eq(e.from).removeClass("active");
        $(".carousel-indicator").eq(e.to).addClass("active");
      });
      $("#btnHelpCarouselPrev").click(function () {
        helpCarousel.prev();
      });

      $("#btnCloseHelpCenter").click(function () {
        navigateToScreen("screenLinkEntry");
      });
      $("#btnCloseHelpCenter1").click(function () {
        navigateToScreen("screenLinkEntry");
      });
      $("#btnCloseHelpCenter2").click(function () {
        navigateToScreen("screenLinkEntry");
      });

      $("#btnAddVisual").click(async function () {
        try {
          const platform = navigator.platform.toLowerCase();
          const tableauLink = $("#inputTableauLink").val();

          if (platform.includes("mac") && tableauLink.includes("online.tableau")) {
            notify("We presently only support Tableau Public on Mac/Safari");
            return;
          }

          const result = loadViz(tableauLink);

          if (result) {
            // setting unique id for content
            const content_id = setNewContentUuid();
            const payload = {
              data_source: tableauLink,
              content_id: content_id,
              type: vizUrlType,
            };
            const res = await postContentUrl(payload);
            if (res.data.access === true) {
              userPlan = "PRO";
            }
            if (userPlan == "PRO") {
              $("#upgradeOverlay").hide();
              $("#btnToolbarControls").prop("disabled", false);
              $("#btnToolbarMenu").prop("disabled", false);
            } else {
              if (tableauLink.includes("public.tableau.com")) {
                $("#upgradeOverlay").hide();
              } else {
                $("#upgradeOverlay").show();
              }
              $("#btnToolbarControls").prop("disabled", true);
              $("#btnToolbarMenu").prop("disabled", true);
            }
            updateFieldsAsPerPlan(userPlan);
            navigateToScreen("screenViz");
            toggleToolbar(true);
            _setSettings("vizUrl", tableauLink);
          }
        } catch (e) {
          if (e?.response?.status === 401) {
            $("#txtUrlError").text("Token expired, Please login again");
            $("#txtUrlError").show();
            setTimeout(function () {
              $("#txtUrlError").hide();
              $("#txtUrlError").text(commonErrorText);
              handleLogoutActions();
            }, 3000);
            return false;
          }
          if (e?.response?.data?.message) {
            $("#txtUrlError").text(e?.response?.data?.message);
          }
          $("#txtUrlError").show();
          setTimeout(function () {
            $("#txtUrlError").hide();
            $("#txtUrlError").text(commonErrorText);
          }, 3000);
          //console.log(e);
        }
      });

      $(".customBtn").each(function () { // doing blur for buttons when menus are closed on click
        var currentEle = this;
        currentEle.addEventListener("click", function () {
          const dataBsAttri = $(this).attr("data-bs-toggle");
          const dataClassAttri = $(this).attr("class");
          if (dataBsAttri === "dropdown" && dataClassAttri?.includes("show")) {
            $(this).blur();
          }
        });
      });

      $("#btnVizEditUrl").click(function () {
        $("#editConfirmationDialog").modal("hide");
        unloadViz();

        navigateToScreen("screenLinkEntry");

        _setSettings("vizUrl", null);
        _setSettings("croppedConfig", null);
      });

      $("#btnVizUndo").click(function (e) {
        e.stopPropagation();
        viz.undoAsync();
      });

      $("#btnVizRedo").click(function () {
        e.stopPropagation();
        viz.redoAsync();
      });

      $("#btnVizReset").click(function () {
        viz.revertAllAsync();
      });

      $("#btnVizRefresh").click(function () {
        viz.refreshDataAsync();
      });

      $("#btnVizLiveToggle").click(function () {
        viz.toggleAutomaticUpdatesAsync();
      });

      $("#btnVizScale").click(function () {
        setToScale();
      });
      $("#btnVizUnScale").click(function () {
        setToUnscale();
      });

      $("#btnDismissNotificationDialog").click(function () {
        $("#notificationDialog").hide();
        $("#notificationDialogText").text("");
      });
    });

    function updateFieldsAsPerPlan(userPlan) {
      if (userPlan == "FREE") {
        $("#item-upgrade-plan").show();
      } else {
        $("#item-upgrade-plan").hide();
      }
    }

    async function login(memberstackData) {
      const withNewToken = { ...memberstackData };
      const memberStackToken = memberstackData?.tokens?.accessToken;
      try {
        const tokenResult = await getToken(memberStackToken);
        // console.log("token result >>", tokenResult);
        const tokens = {
          accessToken: tokenResult?.data?.token,
        };
        user = { ...withNewToken, tokens };
      } catch (err) {
        console.log("on error", err);
      }
      // console.log("user data >>>", user);
      _setSettings("user", user);
      $("#txtLoggedInUser").text(user.member.auth.email);

      try {
        const plan = user.member.planConnections[0].type;
        userPlan = plan;
      } catch (error) {
        userPlan = "FREE";
      }
    }

    function logout() {
      if (!user) return;
      memberstack.logout();
      user = null;
      userPlan = null;
      _setSettings("user", null);
    }

    const setPowerBiView = () => {
      $("#tableauViz").hide();
      $("#tablueVizToolz").hide();
      $("#btnVizScale").hide();
      $("#btnVizUnScale").hide();
      $("#containerVizCrop").css("height", "100vh");
      $("#powerBiView").show();
    }

    const setTablueVizView = () => {
      $("#tableauViz").show();
      $("#tablueVizToolz").show();
      $("#btnVizScale").show();
      $("#btnVizUnScale").show();
      $("#tableauViz").show();
      $("#powerBiView").hide();
    };

    const initPowerBiView = () => {
      setPowerBiView();
      $("#powerBiFrame").attr("src", vizUrl);
    };

    const initTablueViz = () => {
      setTablueVizView();
      const options = {
        device: "desktop",
        hideToolbar: false,
        hideTabs: true,
        onFirstInteractive: OnVizInteractive,
        onFirstVizSizeKnown: OnFirstVizSizeKnown,
      };
      clearExistingViz();
      vizEle = document.getElementById("tableauViz");
      viz = new tableau.Viz(vizEle, vizUrl, options);
    };

    function loadViz(url) {
      const urlRegexExp =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      const regex = new RegExp(urlRegexExp);
      url = url.trim();
      if (url == "" || regex.test(url) == false) {
        throw "Please enter a valid url";
        return false;
      }

      vizUrl = url;

      if (vizUrl.includes("tableau.com")) {
        vizUrlType = "TABLEAU";
      } else if (vizUrl.includes("app.powerbi.com")) {
        vizUrlType = "POWERBI";
      }

      if (vizUrlType === "TABLEAU") {
        // Convert public url to public share
        if (vizUrl.includes("public.tableau.com/app/profile")) {
          vizUrl = vizUrl.replace(/\app\/profile\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\/viz/, "views");
        }
        // removing #/site
        if (vizUrl.includes("#/site")) {
          vizUrl = vizUrl.replace("#/site", "t");
        }
        initTablueViz();
      } else if (vizUrlType === "POWERBI") {
        initPowerBiView();
      }
      return vizUrlType;
    }

    function setToUnscale() {
      $("#btnVizUnScale").hide();
      $("#btnVizScale").show();
      $("#screenViz").removeClass("screenVizScale").addClass("screenVizUnscale");
      $("#containerVizCrop").removeClass("containerVizCropScale").addClass("containerVizCropUnScale");
      $("#tableauViz").css({
        transform: "",
        "transform-origin": "",
      });
      $("#tableauViz").addClass("tableauVizUnscale");
      window.removeEventListener("resize", scaleVizOnEvent);
    }

    function setToScale() {
      $("#btnVizUnScale").show();
      $("#btnVizScale").hide();
      $("#screenViz").removeClass("screenVizUnscale").addClass("screenVizScale");
      $("#containerVizCrop").removeClass("containerVizCropUnScale").addClass("containerVizCropScale");
      $("#tableauViz").removeClass("tableauVizUnscale");
      window.addEventListener("resize", scaleVizOnEvent);
      handleScaleViz();
    }

    function unloadViz() {
      try {
        vizEle.removeEventListener("firstinteractive", function (ev) {
          //console.log("Listener removed - firstinteractive", ev);
        });
        vizEle.removeEventListener("firstvizsizeknown", function (ev) {
          //console.log("Listener removed - firstvizsizeknown", ev);
        });
        window.removeEventListener("resize", function (ev) {
          //console.log("Listener removed - resize", ev);
        });

        // viz?.removeEventListener(tableau?.TableauEventName?.ERROR, onError);

        vizEle = null;
      } catch (error) {}
      // $("#tableauViz").html("").width("100%").height("100%");
      clearExistingViz();
      window.removeEventListener("resize", scaleVizOnEvent);
      $("#tableauViz").html("");
      toggleToolbar(true);
    }

    function OnVizInteractive(event) {
      if (userPlan == "PRO") {
        $("#upgradeOverlay").hide();
        $("#btnToolbarControls").prop("disabled", false);
        $("#btnToolbarMenu").prop("disabled", false);
      } else {
        if (vizUrl.includes("public.tableau.com")) {
          $("#upgradeOverlay").hide();
        } else {
          $("#upgradeOverlay").show();
        }
        $("#btnToolbarControls").prop("disabled", true);
        $("#btnToolbarMenu").prop("disabled", true);
      }
      updateFieldsAsPerPlan(userPlan);
      setTimeout(() => setToScale(), 500);
    }

    function OnFirstVizSizeKnown(ev) {
      // //console.log("first viz size know", ev, ev?.$2?.sheetSize?.maxSize);
      $("#vizLoader").hide();
      const size = ev.detail?.vizSize?.sheetSize?.maxSize || ev?.$2?.sheetSize?.maxSize;
      if (size && size.height && size.width) {
        vizNativeHeight = size.height;
        vizNativeWidth = size.width;
      }
    }

    function scaleViz() {
      // note: u cant hide the toolbar for tableau public vizuals. they just dont let u
      // hence this
      const bIsToolbarPresent = vizUrl.startsWith("https://public");

      const DashboardTarget = document.getElementById("tableauViz");
      const DashboardWrapper = document.getElementById("containerVizCrop");

      if (!!DashboardTarget && !!DashboardWrapper) {
        // toolbar height for tableau public is exactly 27 px it seems
        let DesiredWidth = vizNativeWidth;
        let DesiredHeight = vizNativeHeight + (bIsToolbarPresent ? 27 : 0);

        const WrapperWidth = bAllowUpscaling ? DashboardTarget.clientWidth : DashboardWrapper.clientWidth;
        const WrapperHeight = bAllowUpscaling ? DashboardTarget.clientHeight : DashboardWrapper.clientHeight;
        const ScaleByWidth = () => {
          let Scale = WrapperWidth / DesiredWidth;

          // special logic for upscaling
          if (bAllowUpscaling) {
            DesiredWidth = window.innerWidth;
            DesiredHeight = window.innerHeight + (bIsToolbarPresent ? 27 : 0);
            Scale = DesiredWidth / WrapperWidth;
            const Tolerance = 0;
            const WindowHeight = DashboardWrapper.clientHeight;
            const WindowWidth = window.innerWidth;
            const CurrentWidth = DashboardTarget.clientWidth;
            const CurrentHeight = DashboardTarget.clientHeight;
            const NewHeightCandidate = Scale * CurrentHeight;
            if (NewHeightCandidate > WindowHeight + Tolerance) {
              const Diff = Math.abs(NewHeightCandidate - WindowHeight) + Tolerance;
              DesiredWidth = DesiredWidth - Diff;
              Scale = DesiredWidth / WrapperWidth;
            }

            const NewWidthCandidate = Scale * CurrentWidth;
            if (NewWidthCandidate > WindowWidth + Tolerance) {
              const Diff = Math.abs(NewWidthCandidate - WindowWidth) + Tolerance;
              DesiredWidth = DesiredWidth - Diff;
              Scale = DesiredWidth / WrapperWidth;
            }
            Scale = Math.min(maxUpscaleRatio, Scale);
          }
          Scale = parseFloat(Scale.toString().substring(0, 5));
          //console.log("W-Scale", Scale);
          lastScale = Scale;
          DashboardTarget.style.transform = `scale(${Scale})`;
          if (!hasScaledAtLeastOnce) {
            // call twice on first load because lastScale var wasnt correct the first time
            hasScaledAtLeastOnce = true;
            scaleViz();
          }
        };
        const ScaleByHeight = () => {
          let Scale = WrapperHeight / DesiredHeight;

          // special logic for upscaling
          if (bAllowUpscaling) {
            DesiredWidth = window.innerWidth;
            DesiredHeight = window.innerHeight + (bIsToolbarPresent ? 27 : 0);
            Scale = DesiredHeight / WrapperHeight;

            const Tolerance = 0;
            const WindowHeight = DashboardWrapper.clientHeight;
            const WindowWidth = window.innerWidth;
            const CurrentWidth = DashboardTarget.clientWidth;
            const CurrentHeight = DashboardTarget.clientHeight;
            const NewHeightCandidate = Scale * CurrentHeight;

            if (NewHeightCandidate > WindowHeight + Tolerance) {
              const Diff = Math.abs(NewHeightCandidate - WindowHeight) + Tolerance;
              DesiredHeight = DesiredHeight - Diff;
              Scale = DesiredHeight / WrapperHeight;
            }

            const NewWidthCandidate = Scale * CurrentWidth;
            if (NewWidthCandidate > WindowWidth + Tolerance) {
              const Diff = Math.abs(NewWidthCandidate - WindowWidth) + Tolerance;
              DesiredWidth = DesiredWidth - Diff;
              Scale = DesiredHeight / WrapperHeight;
            }
            Scale = Math.min(maxUpscaleRatio, Scale);
          }
          Scale = parseFloat(Scale.toString().substring(0, 5));
          //console.log("H-Scale", Scale);
          lastScale = Scale;
          DashboardTarget.style.transform = `scale(${Scale})`;
          if (!hasScaledAtLeastOnce) {
            // call twice on first load because lastScale var wasnt correct the first time
            hasScaledAtLeastOnce = true;
            scaleViz();
          }
        };

        const bIsHeightConstrained = WrapperHeight < DesiredHeight;
        const bIsWidthConstrained = WrapperWidth < DesiredWidth;

        //console.log("bIsWidthConstrained", bIsWidthConstrained);
        //console.log("bIsHeightConstrained", bIsHeightConstrained);

        if ((bIsHeightConstrained && bIsWidthConstrained) || bAllowUpscaling) {
          //console.log("Both constrained");
          // if both dimensions are constrained, pick whichever dimension is "more constrained" and use that to scale
          const widthBias = 50;
          const bDoWidth = bAllowUpscaling
            ? DashboardTarget.clientWidth * lastScale + widthBias > window.innerWidth
            : WrapperWidth < WrapperHeight;

          if (bDoWidth) {
            //console.log("Doing width");
            ScaleByWidth();
          } else {
            //console.log("Doing height");
            ScaleByHeight();
          }
        } else if (bIsWidthConstrained) {
          //console.log("Width constrained");
          ScaleByWidth();
        } else if (bIsHeightConstrained) {
          //console.log("Height constrained");
          ScaleByHeight();
        } else {
          // no scaling needed, reset
          //console.log("No scale");
          DashboardTarget.style.transform = `scale(${1.0})`;
          lastScale = 1;
        }
      } else if (!!DashboardTarget) {
        //console.log("No scale");
        DashboardTarget.style.transform = `scale(${1.0})`;
        lastScale = 1;
      }
    }

    function toggleToolbar(forceCollapse, onEvent) {
      const onHideState = isToolbarExpanded || forceCollapse;
      if (onHideState) {
        $("#vizToolbar").hide();
        $("#btnToolbarExpand").show();
        setTimeout(() => {
          $("#btnToolbarExpand").css("opacity", 0);
        }, 6000);
        
      } else {
        $("#vizToolbar").show();
        $("#btnToolbarExpand").hide();
      }
      isToolbarExpanded = !onHideState;
      if (onEvent) handleScaleViz();
    }

    function notify(message, type) {
      $("#notificationDialogText").text(message);
      $("#notificationDialog").show();
      setTimeout(function () {
        $("#notificationDialog").hide();
        $("#notificationDialogText").text("");
      }, 3000);
    }

  };
})();
